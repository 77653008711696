var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" الاعتمادات "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"BecomeInstructorFormvalidate"},[_c('b-form',{staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{model:{value:(_vm.BecomeInstructorForm.description),callback:function ($$v) {_vm.$set(_vm.BecomeInstructorForm, "description", $$v)},expression:"BecomeInstructorForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2",staticStyle:{"margin":"0px"}},[_c('h4',{staticClass:"mb-1"},[_vm._v("header صورة")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-media-body',[_c('b-card-text',{staticClass:"my-50"}),_c('div',{staticClass:"d-inline-block"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Tab Image ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"accept":".jpg, .png, .gif, .jpeg","p":"","placeholder":"اختر ملف"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.BecomeInstructorForm.image),callback:function ($$v) {_vm.$set(_vm.BecomeInstructorForm, "image", $$v)},expression:"BecomeInstructorForm.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"نص بديل للصورة","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"alt-image"},model:{value:(_vm.BecomeInstructorForm.image_caption),callback:function ($$v) {_vm.$set(_vm.BecomeInstructorForm, "image_caption", $$v)},expression:"BecomeInstructorForm.image_caption"}})],1)],1)],1)],1)],1)]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"العنوان","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.BecomeInstructorForm.title),callback:function ($$v) {_vm.$set(_vm.BecomeInstructorForm, "title", $$v)},expression:"BecomeInstructorForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":" العنوان الاضافي ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"meta_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.BecomeInstructorForm.meta_title),callback:function ($$v) {_vm.$set(_vm.BecomeInstructorForm, "meta_title", $$v)},expression:"BecomeInstructorForm.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":" الوصف الاضافي ","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"In-Link Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.BecomeInstructorForm.meta_description),callback:function ($$v) {_vm.$set(_vm.BecomeInstructorForm, "meta_description", $$v)},expression:"BecomeInstructorForm.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":" Meta Keywords","label-for":"blog-content"}},[_c('b-form-tags',{model:{value:(_vm.BecomeInstructorForm.meta_keywords),callback:function ($$v) {_vm.$set(_vm.BecomeInstructorForm, "meta_keywords", $$v)},expression:"BecomeInstructorForm.meta_keywords"}})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" حفظ ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }